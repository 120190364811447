import { useProductsQuery } from 'legoland-sdk/dist/experimental';
import { useMemo } from 'react';

export type CommunicationProduct = {
  id: string;
  displayName: string;
};

const ignoreProductIds = ['rer', 'tv'];

export function useCommunicationProducts() {
  const query = useProductsQuery();

  const products = useMemo(
    () =>
      query.data
        ? [
            { id: 'll', displayName: 'Move' } as CommunicationProduct,
            ...query.data.products,
          ]
            .filter((product) => !ignoreProductIds.includes(product.id))
            .sort((a, b) => a.displayName.localeCompare(b.displayName))
        : [],
    [query.data],
  );

  return { products, query };
}
