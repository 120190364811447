import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ForgotPasswordForm } from './ForgotPasswordForm';

class ForgotPasswordPopup extends Component {
  state = {
    isSubmitted: false,
  };

  handleSuccess = () => {
    this.setState({
      isSubmitted: true,
    });
  };

  render() {
    const { isSubmitted } = this.state;

    const messageId = !isSubmitted
      ? 'auth.forgot-password.title'
      : 'auth.forgot-password.link-has-been-set';

    const form = !isSubmitted ? (
      <ForgotPasswordForm onSuccess={this.handleSuccess} />
    ) : (
      <>
        <p className="form-feedback form-feedback--success LoginPopup__feedback">
          A link to reset your password has been sent to your email address.
        </p>
        <div className="LoginPopup__go-back">
          <Link to="/login">
            <FormattedMessage id="auth.forgot-password.go-back" />
          </Link>
        </div>
      </>
    );

    return (
      <div className="ForgotPasswordPopup LoginPopup__modal py-5">
        <div className="LoginPopup__form">
          <h1 className="LoginPopup__title">
            <FormattedMessage id={messageId} />
          </h1>
          <div className="LoginForm">{form}</div>
        </div>
      </div>
    );
  }
}

export default ForgotPasswordPopup;
