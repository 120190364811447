export function reportsApi({ baseUrl, get, postJson, del }) {
  return {
    async getReportStatus() {
      return await get(`${baseUrl}/report/self/status`);
    },

    async getReportsList({ email, pagination, product }) {
      return await get(
        `${baseUrl}/apps-data/v2/${product}?email=${email}&page=${pagination.index}&size=${pagination.size}`,
      );
    },
    async getReport() {
      return await get(`${baseUrl}/report/self`);
    },
    async getReportAppData() {
      return await get(`${baseUrl}/apps-data/copies`);
    },
    async deleteProcessingReport() {
      return await del(`${baseUrl}/report/self`);
    },
    async requestReport({
      reportType,
      reportFormat,
      productId,
      rangeStart,
      rangeEnd,
      accessType,
      sapIds,
      organizationIds,
      excludeEmailPattern,
      includeEmailPattern,
    }) {
      return await postJson(`${baseUrl}/report/self`, {
        type: reportType,
        format: reportFormat,
        productId: productId,
        from: rangeStart,
        to: rangeEnd,
        accessType,
        sapIds,
        organizationIds,
        excludeEmailPattern,
        includeEmailPattern,
      });
    },
    async copyReportAppData({ srcId, destUser, product }) {
      return await postJson(`${baseUrl}/apps-data/copies`, {
        srcId: srcId,
        destUser: destUser,
        product: product,
      });
    },
    async transferReportAppData({ srcId, destUser, product, srcUser }) {
      return await postJson(`${baseUrl}/apps-data/transfers`, {
        srcId: srcId,
        destUser: destUser,
        product: product,
        srcUser: srcUser,
      });
    },
  };
}
